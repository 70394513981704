import React, { useEffect, useState } from "react";
const moment = require("moment-strftime");
const isMobile = window.innerWidth <= 768;

export function EventOrderItems(props) {
  const [batchesInfosAndQuantities, setBatchesInfosAndQuantities] = useState(
    props.eventBatches.map((eventBatch) => {
      return {
        id: eventBatch.id,
        passType: eventBatch.pass_type,
        name: eventBatch.name,
        ends_at: eventBatch.ends_at,
        priceInCents: eventBatch.price_in_cents,
        feeInCents:
          eventBatch.price_in_cents * parseFloat(props.feePercentage / 100),
        totalInCents:
          eventBatch.price_in_cents *
          (1 + parseFloat(props.feePercentage / 100)),
        quantity: 0, // Inicializa com 0 ou o valor que você deseja
        isSoldOut:
          eventBatch.quantity === 0 ||
          new Date(eventBatch.ends_at) < new Date(), // Verifica se está esgotado
      };
    })
  );

  const [couponCode, setCouponCode] = useState("");
  const [couponResult, setCouponResult] = useState(null);

  const insuranceEnabled = props.event.insurance_enabled;
  const insurancePriceInCents = parseFloat(props.event.insurance_price_in_cents);

  const [insurance, setInsurance] = useState({
    quantity: 0,
    priceInCents: insurancePriceInCents,
    totalInCents: 0,
    selected: insuranceEnabled,
  });

  useEffect(() => {
    const totalIngressos = batchesInfosAndQuantities.reduce(
      (total, batch) => total + batch.quantity,
      0
    );

    if (insurance.selected) {
      setInsurance((prevInsurance) => ({
        ...prevInsurance,
        totalInCents: totalIngressos * prevInsurance.priceInCents,
      }));
    }
  }, [batchesInfosAndQuantities, insurance.selected]);

  const updateQuantity = (batchIndex, amount) => {
    const currentBatches = [...batchesInfosAndQuantities];

    const editedBatchItem = currentBatches[batchIndex];

    if (editedBatchItem.quantity === 0 && amount < 0) return;

    editedBatchItem.quantity =
      currentBatches[batchIndex].quantity + amount;

    // Recalcula o status de "esgotado"
    editedBatchItem.isSoldOut =
      editedBatchItem.quantity === -1 ||
      new Date(editedBatchItem.ends_at) < new Date();

    setBatchesInfosAndQuantities(currentBatches);

    // Atualiza a quantidade do seguro se estiver selecionado
    const totalIngressos = currentBatches.reduce(
      (total, batch) => total + batch.quantity,
      0
    );
    if (insurance.selected) {
      setInsurance((prevInsurance) => ({
        ...prevInsurance,
        quantity: totalIngressos,
        totalInCents: totalIngressos * prevInsurance.priceInCents,
      }));
    }
  };

  const cartTotalInCents = () => {
    // Calcular o total dos ingressos com taxas
    const batchTotal = batchesInfosAndQuantities.reduce((partialSum, batch) => {
      const batchPriceWithFee = batch.priceInCents + batch.feeInCents;
      const totalForBatch = batch.quantity * batchPriceWithFee;
      return partialSum + totalForBatch;
    }, 0);

    // Adicionar o total do seguro se selecionado
    const insuranceTotal = insurance.selected ? insurance.totalInCents : 0;

    return batchTotal + insuranceTotal;
  };

  const applyCoupon = async (specificCoupon = "") => {
    try {
      const response = await axios.get(
        `/api/v1/coupons/${specificCoupon || couponCode}?entity_id=${
          props.event.id
        }&entity_type=Event`
      );
      setCouponResult(response.data);

      if (response.data.success) {
        const applicableBatchId = response.data.coupon.event_batch_id;

        // Atualizar os batches para mostrar os valores com desconto
        setBatchesInfosAndQuantities((prevBatches) =>
          prevBatches.map((batch) => {
            const batchId = Number(batch.id);
            const couponBatchId = Number(applicableBatchId);

            if (batchId === couponBatchId) {
              // Calcula o desconto apenas no preço base
              const discountAmount =
                response.data.coupon.kind === "percentage"
                  ? batch.priceInCents * (response.data.coupon.discount / 100)
                  : response.data.coupon.discount;

              let newPrice = batch.priceInCents - discountAmount;
              newPrice = isNaN(newPrice) || newPrice < 0 ? 0 : newPrice;

              // Calcula a taxa com base no preço com desconto
              const newFeeInCents = newPrice * parseFloat(props.feePercentage / 100);

              // Calcula o total como o preço com desconto mais a taxa
              const newTotalInCents = newPrice + newFeeInCents;

              // Atualiza os valores
              return {
                ...batch, // Manter as outras propriedades
                priceInCents: newPrice,
                feeInCents: newFeeInCents,
                totalInCents: newTotalInCents,
              };
            } else {
              return batch; // Deixar os outros batches inalterados
            }
          })
        );
      } else {
        // Restaurar os valores originais se o cupom for inválido
        setBatchesInfosAndQuantities(originalBatchesInfosAndQuantitIes);
      }
    } catch (error) {
      console.error("Error applying coupon:", error);
      setCouponResult({
        success: false,
        message: "Erro ao buscar cupom",
      });
      setBatchesInfosAndQuantities(originalBatchesInfosAndQuantitIes);
    }
  };

  return (
    <div className="event-batches-order mb-4">
      <div className="header bg-white-color p-4 d-flex justify-content-between align-items-center">
        <h4 className="mb-0 fs-3 text-white">INGRESSOS</h4>

        {/* Carrinho e valor alinhados ao lado oposto */}
        <div className="text-white d-flex align-items-center">
          <i className="fas fa-shopping-cart fs-30 mr-2"></i>
           <span className="px-2 fs-3">
            {(cartTotalInCents() / 100).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })}
          </span>
        </div>
      </div>


      <div className="body border-dark border">
        {batchesInfosAndQuantities.map((batch, index) => {
          return (
            <div
              key={index}
              className="border-bottom border-dark p-4 flex center between"
            >
              <div className="f-40">
                <p className="m-0 f-40 text-dark">
                  {batch.passType} - {batch.name}
                </p>
                <p className="m-0 f-20 text-dark">
                  {(batch.priceInCents / 100).toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })}
                  {props.feePercentage > 0 && (
                    <>
                      &nbsp;(+&nbsp;
                      {(batch.feeInCents / 100).toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      })}
                      &nbsp; taxa)
                    </>
                  )}
                </p>
                <p className="m-0">
                  Vendas até {moment(batch.ends_at).strftime("%d/%m/%Y")}
                </p>
              </div>

              <div
                className={`flex center around ${isMobile ? "f-30" : "f-15"}`}
              >
                {batch.isSoldOut ? (
                  <p className="text-danger">Esgotado</p>
                ) : (
                  <>
                    <i
                      className="fa fa-minus-circle fs-30 text-dark clickable"
                      onClick={() => updateQuantity(index, -1)}
                    ></i>
                    <p className="m-0 text-dark">{batch.quantity}</p>
                    <i
                      className="fa fa-plus-circle fs-30 text-dark clickable"
                      onClick={() => updateQuantity(index, 1)}
                    ></i>
                  </>
                )}
              </div>
              {batch.quantity > 0 && (
                <>
                  <input
                    type="hidden"
                    name="order[order_items][][event_batch_id]"
                    value={batch.id}
                  />
                  <input
                    type="hidden"
                    name="order[order_items][][quantity]"
                    value={batch.quantity}
                  />
                </>
              )}
            </div>
          );
        })}

        {/* Opção de Seguro */}
        {insuranceEnabled && (
         <div className="border-bottom border-dark p-4 d-flex justify-content-between align-items-center">
            {/* Informação do Seguro */}
            <div className="f-40">
              <p className="m-0 f-40 text-dark">Seguro do Ingresso</p>
              <p className="m-0 f-20 text-dark">
                Preço unitário:&nbsp;
                {(insurance.priceInCents / 100).toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </p>
              <a
                href="https://seu-dominio.com/termos-do-seguro"
                target="_blank"
                rel="noopener noreferrer"
                className="f-10 text-dark"
              >
                Termos de Uso
              </a>
            </div>

            {/* Checkbox "Incluir seguro" */}
            <div className="d-flex align-items-center">
              <label className="form-check d-flex align-items-center m-0">
                <input
                  type="checkbox"
                  className="form-check-input ml-4 me-2"
                  checked={insurance.selected}
                  onChange={() => {
                    const totalIngressos = batchesInfosAndQuantities.reduce(
                      (total, batch) => total + batch.quantity,
                      0
                    );
                    setInsurance((prevInsurance) => ({
                      ...prevInsurance,
                      selected: !prevInsurance.selected,
                      quantity: !prevInsurance.selected ? totalIngressos : 0,
                      totalInCents: !prevInsurance.selected
                        ? prevInsurance.priceInCents * totalIngressos
                        : 0,
                    }));
                  }}
                />
                <p className="form-check-label mt-4 text-dark">Incluir seguro</p>
              </label>
            </div>

            {/* Campos ocultos para enviar os dados do seguro */}
            <input
              type="hidden"
              name="insurance_enabled"
              value={insurance.selected ? '1' : '0'}
            />
            <input
              type="hidden"
              name="insurance_price_in_cents"
              value={insurance.totalInCents}
            />
          </div>

        )}

        {/* Seção de Cupom de Desconto */}
        <div
          className={`border-bottom border-dark p-4 d-flex flex-column flex-md-row justify-content-between align-items-center gap-3`}
        >
          <p className="f-20 m-0 text-dark ">Cupom de desconto</p>

          {/* Campo de input responsivo */}
          <input
            type="text"
            name="coupon_code"
            value={couponCode}
            className="form-control flex-grow-1 f-40"
            onChange={(e) => setCouponCode(e.target.value)}
            style={{ maxWidth: "300px", width: "100%" }}
          />

          {/* Botão Aplicar */}
          <p
            className="btn btn-outline-success f-10 m-0 px-5"
            onClick={() => applyCoupon()}
          >
            Aplicar
          </p>

          <div className="f-40 text-center mt-3 mt-md-0">
            {couponResult && (
              <div>
                {!couponResult.success ? (
                  <div className="d-flex align-items-center gap-2">
                    <i className="fa fa-times-circle f-24 text-danger"></i>
                    <p className="m-0 text-danger">{couponResult.message}</p>
                  </div>
                ) : (
                  <div className="d-flex align-items-center gap-2">
                    <i className="fa fa-check-circle f-24 text-primary"></i>
                    <p className="m-0 text-primary">{`Desconto de ${couponResult.discount_display} aplicado`}</p>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
