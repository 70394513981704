document.addEventListener("DOMContentLoaded", function () {
  const confettiContainer = document.getElementById("confetti-container");

  function createConfetti() {
    const confetti = document.createElement("div");
    confetti.classList.add("confetti");
    confetti.style.left = `${Math.random() * 100}vw`;
    confetti.style.animationDuration = `${3 + Math.random() * 2}s`;
    confetti.style.backgroundColor = `hsl(${Math.random() * 360}, 100%, 50%)`;
    confettiContainer.appendChild(confetti);

    setTimeout(() => {
      confetti.remove();
    }, 5000);
  }

  // Verifica se a tela é pequena, e ajusta a quantidade de confetes
  const intervalTime = window.innerWidth < 768 ? 1000 : 300;

  setInterval(createConfetti, intervalTime);
});
